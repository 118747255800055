import React from "react"
import tw from "twin.macro"

import PrimaryCta from "@components/PrimaryCta"

const SecondaryCallout = ({
  title,
  copy,
  cta,
  url,
  evenCorners,
  thinBorder,
  addedStyles,
  ctaWidth,
  ...rest
}) => {
  return (
    <div
      css={[
        tw`border border-cold-purple border-2 rounded-tl-[32px] rounded-tr-[26px] rounded-br-[32px] rounded-bl-[16px] bg-white py-8 px-6 lg:(px-[6.25rem] flex items-center justify-between)`,
        evenCorners && tw`rounded-[32px]`,
        thinBorder && tw`border-[1px]`,
        addedStyles,
      ]}
      {...rest}
    >
      <div>
        {title && (
          <h3
            css={[
              tw`text-lg font-extrabold text-studio mb-4 lg:(text-2xl)`,
              !copy && tw`mb-0`,
            ]}
          >
            {title}
          </h3>
        )}
        {copy && (
          <p css={[tw`text-base font-normal`, cta && tw` mb-6 lg:(mb-0)`]}>
            {copy}
          </p>
        )}
      </div>
      {cta && (
        <PrimaryCta
          url={url}
          addedStyles={[
            tw`px-8 py-2.5 w-auto bg-white border-2 border-cta-red text-cta-red capitalize lg:(px-8 py-2.5 border-cta-red) hover:(text-white border-transparent)`,
            ctaWidth && tw`px-0 lg:(px-0)`,
          ]}
          width={ctaWidth}
        >
          {cta}
        </PrimaryCta>
      )}
    </div>
  )
}

export default SecondaryCallout
